<template>
    <AdminToast/>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <ManagerUserLeftPanel :data-item='dataItem' />
                </div>

                <span>
					<hr>
				</span>

                <span>

                <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger p-component mr-2 pl-3 pr-3 pt-2 pb-2'/>

                <Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">
					<h3>Dosyalar</h3>

					<p class="text-lg">
						<b>Profil Fotoğrafı:</b> Yöneticiye ait fotoğrafın 640x640 piksel .jpg veya .png formatında olması gerekir.<br>	
					</p>
                    <span><hr></span>
                    <span class="text-red-500 font-bold">*</span> doldurulması zorunlu alanlardır.<br>

				</Sidebar>

				</span>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <ManagerUserTabMenu :manager-user-id='managerUserId'></ManagerUserTabMenu>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='field grid mb-5'>
                            <label for='profilePhoto' class='col-12 mb-2 md:col-2 md:mb-0'>Profil Fotoğrafı</label>
                            
                            <div class='col-12 md:col-8'>
                                <InputText id="profilePhoto" type="text" v-model="dataItem.profilePicture" disabled/>
                            </div>
                            
                            <div class="col-12 md:col-2">
                                <FileUpload choose-label="Dosya Seç" mode="basic" name="upload[]" :url="photoUploadUrl" :auto="true" @before-upload='photoUploadStarted' @upload="photoUploadFinished" class="p-button-success"/>
                            </div>
                            
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>

import ManagerUserService from '@/services/managerUserService';
import { showErrorMessage } from '@/components/shared/toast/toastFunction';
import { showSuccessMessage } from '@/components/shared/toast/toastFunction';
import ProjectService from '@/services/projectService';
import ManagerUserProjectService from '@/services/manangerUserProjectService';
import AdminToast from "@/components/shared/toast/adminToast";
import ManagerUserLeftPanel from './managerUserLeftPanel';
import ManagerUserTabMenu from './managerUserTabMenu';
import { checkUserRole } from '../common/commonConstantFunctions';
import { getManagerUser } from '../common/commonFunctions';

export default {
    _managerUserService: null,
    _projectService: null,
    _managerUserProjectService:null,
    components: { ManagerUserTabMenu, AdminToast, ManagerUserLeftPanel},
    created() {
        this._managerUserService = new ManagerUserService();
        this._managerUserProjectService = new ManagerUserProjectService();
        this._projectService = new ProjectService();
        this.managerUserId = this.$route.params.managerUserId;

    },
    async mounted() {
        checkUserRole(this,'Admin');
        this.dataItem = await getManagerUser(this.managerUserId);
        await this.fillProject();
        await this.fillManagerUserProject(this.managerUserId);
        this.photoUploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/static-image?fileType=ProfilePhoto&resourceId=" + this.managerUserId + "&resourceType=ManagerUser";
    },
    data() {
        return {
            loadingIndicator:false,
            photoUploadUrl:"",
            returnField:"",
            canEditSallary:false,
            visibleLeft: false,
            managerUserId: 0,
            dataItem: {
                profilePicture: ''
            },
        };
    },

    methods: {

        async save() {
            this.dataItem.profilePicture = this.dataItem.profilePicture.replace("./","");
            const updateResponse = await this._managerUserService.updateManagerFiles(this.managerUserId, this.dataItem);
            if (updateResponse.isSuccess) {
                showSuccessMessage(this, 'Yönetici Güncelleme Başarılı');
            } else {
                showErrorMessage(this, 'Yönetici Eklemede Sorun İle Karşılaşıldı');
            }
        },
        async fillProject() {
            let projectResponse = await this._projectService.getAllProjects();
            if (projectResponse.isSuccess) {
                this.projectList = projectResponse.data;
            }
        },

        async fillManagerUserProject(managerUserId) {
            let getResponse = await this._managerUserProjectService.getAllProjects(managerUserId);
            if(getResponse.isSuccess)
            {
                this.selectedProjects = getResponse.data;
            }
        },
        async photoUploadFinished(event) {
            this.dataItem.profilePicture = event.xhr.responseText
            await this.save();
            this.loadingIndicator = false;
        },
        photoUploadStarted() {
            this.loadingIndicator = true;
        },
    },

};

</script>


<style scoped>

</style>
